.advantage {
	position: relative;
	margin-bottom: 100px;

	@include pc {
		margin-bottom: 100 * $pc;
	}

	@include tablet {
		margin-bottom: 80 * $tablet;
	}

	@include mobile {
		margin-bottom: 50 * $mobile;
	}

	&__photo {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		border-radius: 50%;
		width: 226px;
		height: 226px;
		background: $color-white;

		@include adaptive('width', 226);
		@include adaptive('height', 226);
		@include adaptive('margin-bottom', 42);

		img,
		svg {
			width: 131px;
			height: 131px;
			fill: $color-black;
			@include adaptive('width', 131);
			@include adaptive('height', 131);
		}

		img {
			display: block;
			margin: 0 auto;
		}
	}

	&__title {
		margin: 0 0 42px;
		font-weight: 700;
		font-size: 34px;
		line-height: 40px;
		color: $color-white;

		@include pc {
			margin: 0 0 42 * $pc;
			font-size: 34 * $pc;
			line-height: 40 * $pc;
		}

		@include tablet {
			margin: 0 0 36 * $tablet;
			font-size: 30 * $tablet;
			line-height: 36 * $tablet;
		}

		@include mobile {
			margin: 0 0 20 * $mobile;
			font-size: 24 * $mobile;
			line-height: 30 * $mobile;
		}
	}

	&__body {
		margin: 0;
		font-size: 24px;
		line-height: 27px;
		color: $color-white;

		@include pc {
			font-size: 24 * $pc;
			line-height: 27 * $pc;
		}

		@include tablet {
			font-size: 24 * $tablet;
			line-height: 27 * $tablet;
		}

		@include mobile {
			font-size: 18 * $mobile;
			line-height: 24 * $mobile;
		}
	}
}