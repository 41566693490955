@mixin showOnHover($parent) {
	& {
		opacity: 0;
		z-index: -100;
		transition: z-index 0.3s step-end, opacity 0.3s ease;

		#{$parent}:hover > & {
			z-index: 999;
			opacity: 1;
			transition: z-index 0.3s step-start, opacity 0.3s ease;
		}
	}
}