.header {
	position: relative;
	left: 0;
	right: 0;
	top: 0;
	z-index: 100;
	width: 100%;

	&.is-absolute {
		position: absolute;
	}

	&__inner {
		position: relative;
		z-index: 200;
		display: flex;
		align-items: center;
		padding: 35px 44px 21px;
		transition: padding 0.2s linear;

		@include pc {
			padding: 35 * $pc 44 * $pc 21 * $pc;
		}

		@include tablet {
			padding: 35 * $tablet 44 * $tablet 21 * $tablet;
		}

		@include mobile {
			align-items: center;
			justify-content: space-between;
			padding: 20 * $mobile 30 * $mobile 18 * $mobile;
		}
	}

	&__content {
		display: flex;
		align-items: center;
		margin-left: auto;

		@include mobile {
			margin: 0 auto;
		}
	}

	&__action {
		display: none;

		@include mobile {
			display: block;
		}
	}

	&__hamburger {
		display: none;

		@include mobile {
			display: block;
		}
	}
}