.phone {
	position: relative;
	display: flex;
	align-items: center;

	&__icon {
		svg {
			width: 58px;
			height: 58px;
			fill: rgba($color-black, 0.1);

			@include pc {
				width: 58 * $pc;
				height: 58 * $pc;
			}

			@include tablet {
				width: 58 * $tablet;
				height: 58 * $tablet;
			}

			@include mobile {
				width: 58 * $mobile;
				height: 58 * $mobile;
			}
		}
	}

	&__number {
		display: block;
		margin-left: 15px;
		font-weight: bold;
		font-size: 36px;
		line-height: 40px;
		text-decoration: none;
		color: $color-lima;

		@include pc {
			margin-left: 15 * $pc;
			font-size: 36 * $pc;
			line-height: 40 * $pc;
		}

		@include tablet {
			margin-left: 15 * $tablet;
			font-size: 36 * $tablet;
			line-height: 40 * $tablet;
		}

		@include mobile {
			margin-left: 12 * $mobile;
			font-size: 24 * $mobile;
			line-height: 36 * $mobile;
		}
	}
}