*,
*::before,
*::after {
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
}

body {
	font-family: $font-roboto;
	background-image: url('../images/site_bg.jpg');
	background-color: $color-lima;
	background-attachment: fixed;
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: center;

	@include mobile {
		background-size: auto 100vh;
		background-attachment: inherit;
		background-repeat: repeat;
		background-position: top center;
	}
	
	&.is-blue-page {
		background-color: $color-tory-blue;
	}
	
	&.is-orange-page {
		background-color: $color-blaze-orange;
	}
}

.is-visually-hidden {
	@include visually-hidden;
}
