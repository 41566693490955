.logo {
	img {
		display: block;
		width: 100%;
		max-width: 124px;

		@include mobile {
			max-width: 85px;
		}
	}
}