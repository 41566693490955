.btn {
	display: block;
	outline: none;
	border-radius: 10px;
	border: none;
	padding: 22px 52px;
	background-color: $color-lima;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	text-decoration: none;
	text-align: center;
	color: $color-black;
	transition: all 0.3s linear;

	&:hover {
		background-color: darken($color-lima, 5%);
	}

	@include pc {
		border-radius: 10 * $pc;
		padding: 22 * $pc 52 * $pc;
		font-size: 18 * $pc;
		line-height: 21 * $pc;
	}

	@include tablet {
		border-radius: 10 * $tablet;
		padding: 22 * $tablet 52 * $tablet;
		font-size: 18 * $tablet;
		line-height: 21 * $tablet;
	}

	@include mobile {
		border-radius: 10 * $mobile;
		padding: 12 * $mobile 16 * $mobile;
		font-size: 16 * $mobile;
		line-height: 20 * $mobile;
	}

	&--white {
		background-color: $color-white;
		color: $color-deep-fir;

		&:hover {
			background-color: darken($color-white, 5%);
		}
	}

	&--yellow {
		background-color: $color-turbo;
		color: $color-deep-fir;

		&:hover {
			background-color: darken($color-turbo, 5%);
		}

		@include pc {
			border-radius: 10 * $pc;
			padding: 26 * $pc 52 * $pc;
			font-size: 21 * $pc;
			line-height: 21 * $pc;
		}
	
		@include tablet {
			border-radius: 10 * $tablet;
			padding: 26 * $tablet 52 * $tablet;
			font-size: 21 * $tablet;
			line-height: 21 * $tablet;
		}
	
		@include mobile {
			border-radius: 10 * $mobile;
			padding: 12 * $mobile 16 * $mobile;
			font-size: 18 * $mobile;
			line-height: 20 * $mobile;
		}
	}
}