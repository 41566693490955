.section {
	&__inner {
		padding: 0 52px;

		@include pc {
			padding: 0 52 * $pc;
		}

		@include tablet {
			padding: 0 36 * $tablet;
		}

		@include mobile {
			padding: 0 20 * $mobile;
		}
	}

	&__header {
		margin: 0 0 60px;
		padding: 0 60px;
		font-weight: 700;
		font-size: 48px;
		line-height: 50px;
		color: $color-white;

		@include pc {
			margin: 0 0 60 * $pc;
			padding: 0 60 * $pc;
			font-size: 48 * $pc;
			line-height: 50 * $pc;
		}

		@include tablet {
			margin: 0 0 40 * $tablet;
			padding: 0 40 * $tablet;
			font-size: 36 * $tablet;
			line-height: 42 * $tablet;
		}

		@include mobile {
			margin: 0 0 30 * $mobile;
			padding: 0 20 * $mobile;
			font-size: 30 * $mobile;
			line-height: 36 * $mobile;
		}

		&.is-mb-tiny {
			margin-bottom: 46px;

			@include pc {
				margin-bottom: 46 * $pc;
			}

			@include pc {
				margin-bottom: 46 * $pc;
			}

			@include tablet {
				margin-bottom: 40 * $tablet;
			}

			@include mobile {
				margin-bottom: 30 * $mobile;
			}
		}
	}

	&__body {
		margin-bottom: 50px;
		padding: 0 60px;
		font-weight: 400;
		font-size: 30px;
		line-height: 38px;
		color: $color-rose-white;

		@include pc {
			margin-bottom: 50 * $pc;
			padding: 0 60 * $pc;
			font-size: 30 * $pc;
			line-height: 38 * $pc;
		}

		@include tablet {
			margin-bottom: 40 * $tablet;
			padding: 0 40 * $tablet;
			font-size: 24 * $tablet;
			line-height: 32 * $tablet;
		}

		@include mobile {
			margin-bottom: 30 * $mobile;
			padding: 0 20 * $mobile;
			font-size: 18 * $mobile;
			line-height: 24 * $mobile;
		}

		&.is-dark {
			padding-top: 66px;
			padding-bottom: 66px;
			background-color: rgba($color-black, 0.3);

			@include pc {
				padding-top: 66 * $pc;
				padding-bottom: 66 * $pc;
			}

			@include tablet {
				padding-top: 40 * $tablet;
				padding-bottom: 40 * $tablet;
			}

			@include mobile {
				padding-top: 20 * $mobile;
				padding-bottom: 20 * $mobile;
			}
		}

		p {
			margin: 0;
		}
	}
}