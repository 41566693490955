@charset "UTF-8";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x           : 0 !default;
$hamburger-padding-y           : 15px !default;
$hamburger-layer-width         : 42px !default;
$hamburger-layer-height        : 4px !default;
$hamburger-layer-spacing       : 7px !default;
$hamburger-layer-color         : $color-white !default;
$hamburger-layer-border-radius : 2px !default;
$hamburger-hover-opacity       : 1 !default;
$hamburger-active-layer-color  : $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter   : false !default;
$hamburger-hover-filter       : opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

// Hamburger
// ==================================================
.hamburger {
  display: inline-block;
  outline: none;
  padding: $hamburger-padding-y $hamburger-padding-x;
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter;
    }
    @else {
      opacity: $hamburger-hover-opacity;
    }
  }

  &.is-active {
    &:hover {
      @if $hamburger-hover-use-filter == true {
        filter: $hamburger-active-hover-filter;
      }
      @else {
        opacity: $hamburger-active-hover-opacity;
      }
    }

    .hamburger__inner,
    .hamburger__inner::before,
    .hamburger__inner::after {
      background-color: $hamburger-active-layer-color;
    }
  }
}

.hamburger__box {
  position: relative;
  display: inline-block;
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
}

.hamburger__inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;

  &,
  &::before,
  &::after {
    position: absolute;
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}

// SPINING
.hamburger {
	&__inner {
		transition-duration: 0.22s;
		transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

		&::before {
			transition: top 0.1s 0.25s ease-in,
			opacity 0.1s ease-in;
		}

		&::after {
			transition: bottom 0.1s 0.25s ease-in,
			transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
		}
	}

	&.is-active {
		.hamburger__inner {
			transform: rotate(225deg);
			transition-delay: 0.12s;
			transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

			&::before {
				top: 0;
				opacity: 0;
				transition: top 0.1s ease-out,
				opacity 0.1s 0.12s ease-out;
			}

			&::after {
				bottom: 0;
				transform: rotate(-90deg);
				transition: bottom 0.1s ease-out,
				transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
			}
		}
	}
}