.mobile-phone {
	position: relative;
	display: flex;
	align-items: center;

	&__icon {
		svg {
			width: 51 * $mobile;
			height: 51 * $mobile;
			fill: $color-turbo;
		}
	}

	&__number {
		display: block;
		margin-left: 12 * $mobile;
		font-weight: bold;
		font-size: 24 * $mobile;
		line-height: 32 * $mobile;
		text-decoration: none;
		color: $color-turbo;
	}
}