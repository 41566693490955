.nav {
	margin: 0;
	padding: 0;

	@include mobile {
		display: none;
	}

	&__list {
		display: flex;
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__item {
		position: relative;
		margin-right: 38px;

		@include pc {
			margin-right: 38 * $pc;
		}

		@include tablet {
			margin-right: 38 * $tablet;
		}

		@include mobile {
			margin-right: 38 * $mobile;
		}
	}

	&__link {
		display: block;
		font-weight: 500;
		font-size: 25px;
		line-height: 57px;
		text-decoration: none;
		color: $color-white;
		transition: color 0.3s linear;

		&:hover {
			color: rgba($color-white, 0.7);
		}

		@include pc {
			font-size: 25 * $pc;
			line-height: 57 * $pc;
		}

		@include tablet {
			font-size: 25 * $tablet;
			line-height: 57 * $tablet;
		}

		@include mobile {
			font-size: 25 * $mobile;
			line-height: 57 * $mobile;
		}
	}
}