.hero {
	position: relative;
	z-index: 1;
	width: 100%;

	&__inner {
		position: relative;
		z-index: 10;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		height: 100%;
	}

	&__title {
		margin: auto 0;
		font-weight: 100;
		font-size: 72px;
		line-height: 74px;
		color: $color-white;

		@include pc {
			font-size: 72 * $pc;
			line-height: 74 * $pc;
		}

		@include tablet {
			font-size: 60 * $tablet;
			line-height: 64 * $tablet;
		}

		@include mobile {
			margin: 0 0 auto;
			padding-top: 30 * $mobile;
			font-weight: 500;
			font-size: 32 * $mobile;
			line-height: 38 * $mobile;
		}
	}

	&__slider {
		overflow: hidden;
		opacity: 0;
		height: 0;
		transition: opacity 300ms linear;
	
		&.slick-initialized {
			height: auto;
			opacity: 1;

			.hero__content {
				padding: 190px 0 90px 200px;
				max-width: 640px;
				width: 100%;
				height: 100%;
				opacity: 1;

				@include pc {
					padding: 190 * $pc 0 90 * $pc 200 * $pc;
					max-width: 720 * $pc;
				}

				@include tablet {
					padding: 190 * $tablet 0 90 * $tablet 200 * $tablet;
					max-width: 700 * $tablet;
				}

				@include mobile {
					padding: 90 * $mobile 20 * $mobile;
					max-width: 100%;
				}
			}
		}
	}

	&__action {
		@include mobile {
			align-self: center;
		}
	}

	&__item {
		display: block;
		overflow: hidden;
		position: relative;
		height: 100vh;
		background-position: center;
		background-repeat: repeat-x;

		&.is-bg-cover {
			background-size: cover;
		}

		&.is-product {
			height: 70vh;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		overflow: visible;
		opacity: 0;
		height: 0;
		z-index: 100;
	}

	&__controls {
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		z-index: 150;
		width: 100%;

		@include mobile {
			// display: none;
		}
	}

	&__prev,
	&__next {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		width: 48px;
		height: 48px;
		background-color: $color-white;
		transform: translateY(-50%);
		transition: background 0.15s linear,
								left 0.15s linear,
								right 0.15s linear,
								width 0.15s linear,
								height 0.15s linear;
		cursor: pointer;

		@include adaptive('width', 48);
		@include adaptive('height', 48);

		svg {
			width: 17px;
			height: 29px;
			fill: $color-black;
			transition: fill 0.3s linear;

			@include adaptive('width', 17);
			@include adaptive('height', 29);

			@include mobile {
				width: 9 * $mobile;
				height: 15 * $mobile;
			}
		}

		&:hover {
			width: 142px;
			height: 142px;
			@include adaptive('width', 142);
			@include adaptive('height', 142);

			@include mobile {
				width: 48 * $mobile;
				height: 48 * $mobile;
			}

			.hero__label {
				opacity: 1;
				transition: 0.3s opacity 0.3s linear;
			}

			&.is-green {
				background-color: #409f17;

				svg {
					fill: $color-white;
				}
			}

			&.is-orange {
				background-color: #ff650d;

				svg {
					fill: $color-black;
				}
			}

			&.is-blue {
				background-color: #1847ab;

				svg {
					fill: $color-white;
				}
			}
		}
	}

	&__prev {
		left: 94px;
		@include adaptive('left', 94);

		@include mobile {
			left: 20 * $mobile;
		}

		svg {
			transform: translateX(-15%);
		}

		&:hover {
			left: 47px;
			@include adaptive('left', 47);

			@include mobile {
				left: 20 * $mobile;
			}
		}
	}

	&__next {
		right: 94px;
		@include adaptive('right', 94);
		@include mobile {
			right: 20 * $mobile;
		}

		svg {
			transform: translateX(15%);
		}

		&:hover {
			right: 47px;
			@include adaptive('right', 47);
			@include mobile {
				right: 20 * $mobile;
			}
		}
	}

	&__label {
		opacity: 0;
		position: absolute;
		right: 100%;
		top: 50%;
		z-index: 100;
		transform: translate(50%, -50%);
		padding: 11px 20px;
		border-radius: 10px;
		background-color: $color-white;
		font-weight: 700;
		font-size: 24px;
		line-height: 25px;
		color: $color-tory-blue;
		transition: none;

		@include pc {
			padding: 11 * $pc 20 * $pc;
			border-radius: 10 * $pc;
			font-size: 24 * $pc;
			line-height: 25 * $pc;
		}

		@include tablet {
			padding: 11 * $tablet 20 * $tablet;
			border-radius: 10 * $tablet;
			font-size: 24 * $tablet;
			line-height: 25 * $tablet;
		}

		@include mobile {
			display: none;
			padding: 11 * $mobile 20 * $mobile;
			border-radius: 10 * $mobile;
			font-size: 24 * $mobile;
			line-height: 25 * $mobile;
		}

		.is-orange & {
			color: $color-blaze-orange;
		}

		.is-green & {
			color: $color-la-palma;
		}

		.is-blue & {
			color: $color-tory-blue;
		}

		.hero__prev & {
			left: 100%;
			right: auto;
			transform: translate(-50%, -50%);
		}
	}
}