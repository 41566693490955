.page {
	&__title {
		position: relative;
		margin: 0 0 45px;
		font-weight: 700;
		font-size: 48px;
		line-height: 50px;
		text-align: center;
		color: $color-white;

		@include pc {
			margin: 0 0 45 * $pc;
			font-size: 48 * $pc;
			line-height: 50 * $pc;
		}

		@include tablet {
			margin: 0 0 40 * $tablet;
			font-size: 42 * $tablet;
			line-height: 46 * $tablet;
		}

		@include mobile {
			margin: 0 0 30 * $mobile;
			font-size: 36 * $mobile;
			line-height: 42 * $mobile;
		}

		// &.with-bg::after {
		// 	content: "";
		// 	position: absolute;
		// 	top: -80px;
		// 	left: 0;
		// 	right: 200px;
		// 	z-index: -1;
		// 	margin: 0 auto;
		// 	width: 680px;
		// 	height: 573px;
		// 	background-position: center top;
		// 	background-repeat: no-repeat;
		// 	background-size: contain;

		// 	@include pc {
		// 		top: -80 * $pc;
		// 		right: 200 * $pc;
		// 		width: 720 * $pc;
		// 		height: 573 * $pc;
		// 	}

		// 	@include tablet {
		// 		top: -50 * $tablet;
		// 		right: 200 * $tablet;
		// 		width: 680 * $tablet;
		// 		height: 573 * $tablet;
		// 	}

		// 	@include mobile {
		// 		display: none;
		// 	}
		// }

		// &.is-red::after {
		// 	background-image: url('../images/header-bg/red_green.png');
		// }

		// &.is-green::after {
		// 	background-image: url('../images/header-bg/green_blue.png');
		// }

		// &.is-yellow::after {
		// 	background-image: url('../images/header-bg/yellow_orange.png');
		// }
	}

	&__photo {
		margin-bottom: 75px;
		min-height: 70vh;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center top;

		@include pc {
			min-height: 70vh;
			margin-bottom: 75 * $pc;
		}

		@include tablet {
			min-height: 70vh;
			margin-bottom: 50 * $tablet;
		}

		@include mobile {
			min-height: 70vh;
			margin-bottom: 30 * $mobile;
		}
	}

	&__slider {
		margin-bottom: 76px;

		@include pc {
			margin-bottom: 76 * $pc;
		}

		@include tablet {
			margin-bottom: 50 * $tablet;
		}

		@include mobile {
			margin-bottom: 30 * $mobile;
		}
	}
}