.slick-arrow {
	opacity: 1;
	transition: opacity 0.3s linear;

	&.slick-disabled {
		opacity: 0.3;
	}
}

.slick-slide > div {
	font-size: 0;
	line-height: 0;
}

.slick-dots {
	display: none !important;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 30 * $mobile;
	align-items: center;
	justify-content: center;
	list-style: none;
	margin: 0 auto;
	padding: 0;
	width: 100%;

	@include mobile {
		display: flex !important;
	}

	li {
		border: none;
		border-radius: 50%;
		background: rgba($color-black, 0.3);
		text-indent: -9999px;
		margin-right: 20 * $mobile;
		width: 20 * $mobile;
		height: 20 * $mobile;

		&:last-child {
			margin-right: 0;
		}

		&.slick-active {
			background: $color-white;
		}
	}
}