.mobile-location {
	&__item {
		display: flex;
		align-items: center;
		position: relative;
		margin: 0 0 16 * $mobile;
		text-decoration: none;

		&.is-lima {
			color: $color-lima;
		}
	}

	&__icon {
		margin-right: 19 * $mobile;

		svg {
			width: 32 * $mobile;
			height: 45 * $mobile;
			fill: $color-lima;
		}
	}

	&__text {
		display: block;
		margin: 0;
		font-weight: 300;
		font-size: 18 * $mobile;
		line-height: 20 * $mobile;
		text-decoration: none;
		color: $color-white;

		.is-lima & {
			color: $color-lima;
		}

		&.is-medium {
			font-weight: 500;
		}
	}
}