.footer {
	&__inner {
		position: relative;
		z-index: 200;
		display: flex;
		align-items: stretch;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 54px 50px;
		background: $color-white;

		@include pc {
			padding: 54 * $pc 50 * $pc;
		}

		@include tablet {
			padding: 54 * $tablet 50 * $tablet;
		}

		@include mobile {
			padding: 27 * $mobile 25 * $mobile;
		}
	}

	&__header {
		margin: 0 0 24px;
		font-weight: 500;
		font-size: 18px;
		line-height: 20px;
		color: $color-black;

		@include pc {
			margin: 0 0 24 * $pc;
			font-size: 18 * $pc;
			line-height: 20 * $pc;
		}

		@include tablet {
			margin: 0 0 24 * $tablet;
			font-size: 18 * $tablet;
			line-height: 20 * $tablet;
		}

		@include mobile {
			margin: 0 0 24 * $mobile;
			font-size: 18 * $mobile;
			line-height: 20 * $mobile;
		}

		&.is-shifted {
			padding-left: 48px;
	
			@include pc {
				padding-left: 48 * $pc;
			}
	
			@include tablet {
				padding-left: 48 * $tablet;
			}
	
			@include mobile {
				padding-left: 48 * $mobile;
			}
		}
	}

	&__text {
		margin: 0 0 16px;
		font-weight: 300;
		font-size: 18px;
		line-height: 20px;
		color: $color-black;

		@include pc {
			margin: 0 0 16 * $pc;
			font-size: 18 * $pc;
			line-height: 20 * $pc;
		}

		@include tablet {
			margin: 0 0 16 * $tablet;
			font-size: 18 * $tablet;
			line-height: 20 * $tablet;
		}

		@include mobile {
			margin: 0 0 16 * $mobile;
			font-size: 18 * $mobile;
			line-height: 20 * $mobile;
		}
	}

	&__about {
		display: flex;
		flex-direction: column;
		width: percentage(2 / 4);

		@include tablet {
			order: 3;
			padding-top: 30 * $tablet;
			width: percentage(4 / 4);
		}

		@include mobile {
			br {
				display: none;
			}
		}
	}

	&__contacts {
		width: percentage(1 / 4);

		@include tablet {
			order: 1;
			width: percentage(2 / 4);
		}

		@include mobile {
			width: percentage(4 / 4);
		}
	}

	&__order {
		width: percentage(1 / 4);

		@include tablet {
			order: 2;
			width: percentage(2 / 4);
		}

		@include mobile {
			width: percentage(4 / 4);
		}
	}

	&__copyrights {
		margin: auto 0 0;
		font-weight: 300;
		font-size: 14px;
		line-height: 20px;
		color: $color-black;

		@include adaptive('font-size', 14);
		@include adaptive('line-height', 20);
	}

	&__item {
		@include mobile {
			margin-bottom: 30 * $mobile;
		}
	}
}