.submenu {
	position: absolute;
	left: 0;
	top: 100%;
	list-style: none;
	margin: 0;
	padding: 21px;
	border-radius: 10px;
	width: 420px;
	background-color: $color-lima;
	@include showOnHover('.nav__item');
	transform: translateX(-25%);

	@include pc {
		padding: 21 * $pc;
		border-radius: 10 * $pc;
		width: 420 * $pc;
	}

	@include tablet {
		padding: 21 * $tablet;
		border-radius: 10 * $tablet;
		width: 420 * $tablet;
	}

	@include mobile {
		padding: 21 * $mobile;
		border-radius: 10 * $mobile;
		width: 420 * $mobile;
	}

	&::after {
		content: " ";
		position: absolute;
		left: 50%;
		top: -10px;
		z-index: 1;
		@include triangle(to top, 48px, 24px, $color-lima);
		transform: translateX(-50%);
	}

	&__item {
		margin-bottom: 15px;

		&:last-child {
			margin: 0;
		}
	}

	&__link {
		display: block;
		font-weight: 500;
		font-size: 24px;
		line-height: 30px;
		text-decoration: none;
		color: $color-black;
		transition: color 0.3s linear;

		@include pc {
			font-size: 24 * $pc;
			line-height: 30 * $pc;
		}

		@include tablet {
			font-size: 24 * $tablet;
			line-height: 30 * $tablet;
		}

		@include mobile {
			font-size: 24 * $mobile;
			line-height: 30 * $mobile;
		}

		&:hover {
			color: rgba($color-black, 0.7);
		}
	}
}