.location {
	&__item {
		position: relative;
		margin: 0 0 16px;
		padding-left: 48px;

		@include pc {
			margin: 0 0 16 * $pc;
			padding-left: 48 * $pc;
		}

		@include tablet {
			margin: 0 0 16 * $tablet;
			padding-left: 48 * $tablet;
		}

		@include mobile {
			margin: 0 0 16 * $mobile;
			padding-left: 48 * $mobile;
		}
	}

	&__icon {
		position: absolute;
		left: 0;
		top: 0;

		svg {
			width: 32px;
			height: 45px;
			fill: $color-lima;

			@include pc {
				width: 32 * $pc;
				height: 45 * $pc;
			}

			@include tablet {
				width: 32 * $tablet;
				height: 45 * $tablet;
			}

			@include mobile {
				width: 32 * $mobile;
				height: 45 * $mobile;
			}
		}
	}

	&__text {
		display: block;
		margin: 0;
		font-weight: 300;
		font-size: 18px;
		line-height: 20px;
		text-decoration: none;
		color: $color-black;

		@include pc {
			font-size: 18 * $pc;
			line-height: 20 * $pc;
		}

		@include tablet {
			font-size: 18 * $tablet;
			line-height: 20 * $tablet;
		}

		@include mobile {
			font-size: 18 * $mobile;
			line-height: 20 * $mobile;
		}

		&.is-medium {
			font-weight: 500;
		}
	}
}