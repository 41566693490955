.mobile-menu {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: -100;
	opacity: 0;
	visibility: hidden;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0;
	height: 100%;
	background: url('../images/site_bg.jpg') no-repeat center top;
	background-size: cover;
	box-shadow: inset 0 0 0 2000px rgba($color-black, 0.3);
	transition: z-index 0.3s step-end, opacity 0.3s ease;

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		flex-shrink: 0;
		margin: 0 auto;
		padding: 20 * $mobile 30 * $mobile 25 * $mobile;
		width: 100%;
	}

	&__title {
		margin: 0 0 20 * $mobile;
		font-weight: 500;
		font-size: 24 * $mobile;
		line-height: 24 * $mobile;
		color: $color-white;

		&.is-shifted {
			margin: 0 0 10 * $mobile;
			font-weight: 400;
			font-size: 18 * $mobile;
			line-height: 18 * $mobile;
			text-align: right;
		}
	}

	&__footer {
		display: flex;
		flex-shrink: 0;
		flex-direction: column;
		margin-top: auto;
		padding: 24 * $mobile 40 * $mobile;
	}

	&__hamburger {
		.hamburger.is-active {
			.hamburger__inner,
			.hamburger__inner::before,
			.hamburger__inner::after {
				background: $color-turbo;
			}
		}
	}

	&__inner {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		margin: 0 auto;
		width: 100%;
		
		@media (orientation: landscape) {}
	}

	&__list {
		position: relative;
		list-style: none;
		margin: 20 * $mobile 0 60 * $mobile;
		padding: 0;
		width: 100%;
	}

	&__item {
		display: block;
		padding: 0 18 * $mobile;
	}

	&__link {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 12 * $mobile 18 * $mobile 12 * $mobile 20 * $mobile;
		font-weight: 500;
		font-size: 25 * $mobile;
		line-height: 25 * $mobile;
		text-decoration: none;
		color: $color-white;
		transition: all 0.3s;

		&:hover,
		&.is-active {
			color: darken($color-turbo, 15%);
		}
	}

	@include mobile {
		&.is-open {
			z-index: 999;
			opacity: 1;
			visibility: visible;
			transition: z-index 0.3s step-start, opacity 0.3s ease;
		}
	}
}