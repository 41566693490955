
@mixin pc {
	@media (min-width: 1101px) {
		@content;
	}
}

@mixin tablet {
	@media (max-width: 1100px) {
		@content;
	}
}

@mixin mobile {
	@media (max-width: 800px) {
		@content;
	}
}

@mixin row() {
	display: flex;
	flex-wrap: wrap;
	margin-left: -52px;

	&.is-vtop {
		align-items: flex-start;
	}

	&.is-vcenter {
		align-items: center;
	}

	&.is-vbottom {
		align-items: flex-end;
	}

	@include pc {
		margin-left: -52 * $pc;
	}

	@include tablet {
		margin-left: -52 * $tablet;
	}

	@include mobile {
		flex-wrap: wrap;
		margin-left: -52 * $mobile;
	}
}

@mixin col() {
	padding-left: 52px;

	&.is-flex {
		display: flex;
	}

	&.is-vcenter {
		align-self: center;
	}

	&.col-1-of-3 {
		width: percentage(1 / 3);
	}

	&.col-1-of-4 {
		width: percentage(1 / 4);
	}

	&.col-1-of-5 {
		width: percentage(1 / 5);
	}

	@include pc {
		padding-left: 52 * $pc;
	}

	@include tablet {
		padding-left: 52 * $tablet;

		&.col-md-4 {
			width: percentage(4 / 12);
		}

		&.col-md-6 {
			width: percentage(6 / 12);
		}
	}

	@include mobile {
		padding-left: 52 * $mobile;

		&.col-sm-12 {
			width: percentage(12 / 12);
		}
	}
}

@mixin adaptive($option, $value) {
	@include pc {
		#{$option}: $value * $pc;
	}

	@include tablet {
		#{$option}: $value * $tablet;
	}

	@include mobile {
		#{$option}: $value * $mobile;
	}
}