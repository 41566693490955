// stylelint-disable scss/dollar-variable-empty-line-before

// COLORS
$color-black: #000;
$color-deep-fir: #000100;
$color-white: #fff;
$color-rose-white: #fffefe;
$color-lima: #60d50b;
$color-cerulean: #00a4e0;
$color-turbo: #eee400;
$color-blaze-orange: #ff650d;
$color-tory-blue: #1847ab;
$color-la-palma: #409f17;

// FONTS
$font-roboto: 'Roboto', sans-serif;

// APAPRIVE SIZES
$pc: 1 / 2000 * 100vw;
$tablet: 1 / 992 * 100vw;
$mobile: 1 / 360 * 100vmin;

// CONTAINERS
$container-pc: 1400 * $pc;
$container-tablet: 992 * $tablet;
$container-mobile: 350 * $mobile;