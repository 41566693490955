.card {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 50px;
	min-height: 670px;
	background: $color-white;

	@include pc {
		margin-bottom: 50 * $pc;
		min-height: 670 * $pc;
	}

	@include tablet {
		margin-bottom: 40 * $tablet;
		min-height: 0;
	}

	@include mobile {
		margin-bottom: 30 * $mobile;
	}

	&.is-reversed {
		flex-direction: row-reverse;
	}

	&__photo {
		width: percentage(1 / 2);
		background-size: cover;
		background-position: 0 0;
		background-repeat: no-repeat;

		@include tablet {
			width: percentage(2 / 2);
			min-height: 300 * $tablet;
		}

		@include mobile {
			min-height: 300 * $mobile;
		}
	}

	&__content {
		display: flex;
		flex-shrink: 0;
		flex-direction: column;
		justify-content: center;
		padding: 60px;
		width: percentage(1 / 2);

		@include pc {
			padding: 60 * $pc;
		}

		@include tablet {
			padding: 40 * $tablet;
			width: percentage(2 / 2);
		}

		@include mobile {
			padding: 20 * $mobile;
		}
	}

	&__title {
		margin: 0 0 42px;
		font-weight: 700;
		font-size: 48px;
		line-height: 50px;
		color: $color-black;

		@include pc {
			margin: 0 0 42 * $pc;
			font-size: 48 * $pc;
			line-height: 50 * $pc;
		}

		@include tablet {
			margin: 0 0 42 * $tablet;
			font-size: 36 * $tablet;
			line-height: 40 * $tablet;
		}

		@include mobile {
			margin: 0 0 30 * $mobile;
			font-size: 32 * $mobile;
			line-height: 36 * $mobile;
		}
	}
	
	&__body {
		margin: 0;
		font-weight: 400;
		font-size: 24px;
		line-height: 32px;
		color: $color-black;

		@include pc {
			font-size: 24 * $pc;
			line-height: 32 * $pc;
		}

		@include tablet {
			font-size: 24 * $tablet;
			line-height: 30 * $tablet;
		}

		@include mobile {
			font-size: 18 * $mobile;
			line-height: 24 * $mobile;
		}

		p {
			margin: 0;
		}
	}
}